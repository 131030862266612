<template>
  <div class="card" :class="darkDark !== 'white' ? 'darkCard' : 'card'">
    <!-- <div class="card-header" v-if="title">{{ title }}</div> -->
    <div class="card-body">
      <p class="card-title"></p>

      <div
        id="dataTables-example_wrapper"
        class="dataTables_wrapper dt-bootstrap4 no-footer"
      >
        <div class="row">
          <div class="col-sm-12">
            <table
              class="border-collapse border border-green-800 w-full table-auto"
              id="dataTables-example"
              :style="{ color, overflowX: 'hidden' }"
            >
              <thead
                class="bg-gray-200 text-gray-600 text-sm leading-normal"
                :style="{
                  color:
                    this.darkDark !== 'white' ? '#cccccc' : 'var(--textColor)',
                  // background: headBackground || (this.darkDark? '#B0B0B0': '#111111'),
                  height: '30px',
                }"
              >
                <tr>
                  <th
                    :class="'text-center'"
                    v-for="(col, index) in columns"
                    :key="index"
                    :style="{
                      textAlign: 'center',
                      width: '100%',
                      position: 'sticky',
                      top: '-1px',
                      background:
                        this.darkDark !== 'white'
                          ? 'var(--nav1BgClr)'
                          : '#B0B0B0',
                      transition: 'var(--transition)',
                    }"
                  >
                    <div class="custom-select-table">
                      <div
                        class="selected flex justify-center items-center"
                        style="line-height: 11px"
                      >
                        <span
                          class="uppercase"
                          :style="
                            headFontSize > 5
                              ? { fontSize: `${headFontSize}px` }
                              : {}
                          "
                          >{{
                            // col.label.split(" ")[0] ,

                            col.label.trim() == "drillingdifficultyindex"
                              ? "Drilling Difficulty Index"
                              : col.label.trim() == "drillingdifficultyremarks"
                              ? "Remarks"
                              : col.label.split(" ")[0] + "  "
                          }}</span
                        >
                        <br />
                        <span v-if="col.label.split(' ')[1]">{{
                          // "  " + (col.label.split(" ")[1])
                          `  (${col.label.split(" ")[1]})`
                        }}</span>
                        <br />
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody v-if="rows.length > 0" class="text-black text-sm">
                <tr
                  class="border-gray-200"
                  v-for="(row, i) in rows"
                  :key="i"
                  :style="
                    i % 2 == 0
                      ? {
                          color: this.darkDark !== 'white' ? '#fff' : 'black',
                          backgroundColor:
                            this.darkDark !== 'white' ? 'var(--navBar2Bg)' : '',
                        }
                      : {
                          color: this.darkDark !== 'white' ? '#fff' : 'black',
                          backgroundColor: 'var(--root_nav)',
                        }
                  "
                >
                  <td
                    class="text-left overflow-hidden whitespace-nowrap auto-font py-1.5"
                    :style="{
                      // fontSize: `${headFontSize}px`,

                      textAlign: 'center',
                    }"
                    v-for="(col, j) in columns"
                    :key="j"
                  >
                    <!-- {{ row[col.field] }} -->
                    <!-- <div v-if="col.display" v-html="col.display(row)"></div> -->
                    <span>{{ row[col.field] }}</span>
                    <!-- <span v-else>{{ row[col.field] ? parseFloat(row[col.field]).toFixed(1) : "" }}</span> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="row"
          v-if="rows.length > 0"
          :style="{ width: `${width}%` }"
        ></div>
        <div class="row" v-else>
          <div
            :style="{
              color: this.darkDark !== 'white' ? '#cccccc' : 'var(--textColor)',
            }"
            class="col-sm-12 text-center"
          >
            {{ messages.noDataAvailable }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// String.prototype.format = function () {
//   var args = arguments;
//   console.log("arguments ", arguments)
//   return this.replace(/{([0-9]*)}/g, function (match, number) {
//     return typeof args[number] != "undefined" ? args[number] : match;
//   });
// };
import {
  defineComponent,
  ref,
  reactive,
  computed,
  watch,
  onBeforeUpdate,
  nextTick,
} from "vue";

export default defineComponent({
  name: "my-table",
  props: {
    alertColor: {
      type: String,
    },
    widgetPropertyIndex: {
      type: Number,
    },
    tableId: {
      type: String,
    },
    color: {
      type: String,
    },
    background: {
      type: String,
    },
    height: {
      type: [String, Number],
    },
    width: {
      type: [String, Number],
    },
    headColor: {
      type: String,
    },
    headBackground: {
      type: String,
    },
    headFontSize: {
      type: String,
      // default: "14",
    },
    isLoading: {
      type: Boolean,
      require: true,
    },
    isReSearch: {
      type: Boolean,
      require: true,
    },
    hasCheckbox: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    rows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 100,
    },
    page: {
      type: Number,
      default: 1,
    },
    sortable: {
      type: Object,
      default: () => {
        return {
          order: "id",
          sort: "asc",
        };
      },
    },
    tableConfiguration: Object,
    messages: {
      type: Object,
      default: () => {
        return {
          pagingInfo: "Showing {0}-{1} of {2}",
          pageSizeChangeLabel: "Row count:",
          gotoPageLabel: "Go to page:",
          noDataAvailable: "No data",
        };
      },
    },
  },
  data() {
    return {
      dropdownPopoverShow: false,
      // image: image,
      backgroundColor: this.background,
    };
  },
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    dark() {
      this.$emit("dark");
    },

    light() {
      this.$emit("light");
    },
  },

  mounted() {
    // color:  (this.$store.state.rect.rects[this.tableId][col.field+'StartRange'] > 100 && this.$store.state.rect.rects[id][col.field+'EndRange'] < 150) ? '#ff0000' :this.$store.state.rect.rects[id][col.field+'EndRange'] > 150 ? '#FF4500':row[col.field]>300?'#FF4500' :color,
    //
    //   "eeeeeeeeeeeeeeeeeeee",
    //   this.$store.state.rect.rects[this.tableId].table
    // );
  },
  setup(props, { emit }) {
    const setting = reactive({
      isCheckAll: false,
      keyColumn: computed(() => {
        let key = "";
        props.columns.forEach((col) => {
          if (col.isKey) {
            key = col.field;
          }
        });
        return key;
      }),
      page: props.page,
      pageSize: props.pageSize,
      maxPage: computed(() => {
        if (props.total <= 0) {
          return 0;
        }
        let maxPage = Math.floor(props.total / setting.pageSize);
        let mod = props.total % setting.pageSize;
        if (mod > 0) {
          maxPage++;
        }
        return maxPage;
      }),

      offset: computed(() => {
        return (setting.page - 1) * setting.pageSize + 1;
      }),

      limit: computed(() => {
        let limit = setting.page * setting.pageSize;
        return props.total >= limit ? limit : props.total;
      }),
      pagging: computed(() => {
        let startPage = setting.page - 2 <= 0 ? 1 : setting.page - 2;
        if (setting.maxPage - setting.page <= 2) {
          startPage = setting.maxPage - 4;
        }
        startPage = startPage <= 0 ? 1 : startPage;
        let pages = [];
        for (let i = startPage; i <= setting.maxPage; i++) {
          if (pages.length < 5) {
            pages.push(i);
          }
        }
        return pages;
      }),
    });

    const rowCheckbox = ref([]);
    if (props.hasCheckbox) {
      onBeforeUpdate(() => {
        rowCheckbox.value = [];
      });

      watch(
        () => setting.isCheckAll,
        (state) => {
          let isChecked = [];
          rowCheckbox.value.forEach((val) => {
            if (val) {
              val.checked = state;
              if (val.checked == true) {
                isChecked.push(val.value);
              }
            }
          });
          emit("return-checked-rows", isChecked);
        }
      );
    }

    const checked = () => {
      let isChecked = [];
      rowCheckbox.value.forEach((val) => {
        if (val && val.checked == true) {
          isChecked.push(val.value);
        }
      });
      emit("return-checked-rows", isChecked);
    };

    const clearChecked = () => {
      rowCheckbox.value.forEach((val) => {
        if (val && val.checked == true) {
          val.checked = false;
        }
      });
      emit("return-checked-rows", []);
    };

    const doSort = (order) => {
      let sort = "asc";
      if (order == props.sortable.order) {
        if (props.sortable.sort == "asc") {
          sort = "desc";
        }
      }
      let offset = (setting.page - 1) * setting.pageSize;
      let limit = setting.pageSize;
      emit("do-search", offset, limit, order, sort);

      if (setting.isCheckAll) {
        setting.isCheckAll = false;
      } else {
        if (props.hasCheckbox) {
          clearChecked();
        }
      }
    };

    /**
     * 切換頁碼
     *
     * @param number page     新頁碼
     * @param number prevPage 現在頁碼
     */
    const changePage = (page, prevPage) => {
      setting.isCheckAll = false;
      let order = props.sortable.order;
      let sort = props.sortable.sort;
      let offset = (page - 1) * setting.pageSize;
      let limit = setting.pageSize;
      if (!props.isReSearch || page > 1 || page == prevPage) {
        emit("do-search", offset, limit, order, sort);
      }
    };
    // 監聽頁碼切換
    watch(() => setting.page, changePage);

    /**
     * 切換顯示筆數
     */
    const changePageSize = () => {
      if (setting.page === 1) {
        changePage(setting.page, setting.page);
      } else {
        setting.page = 1;
        setting.isCheckAll = false;
      }
    };
    watch(() => setting.pageSize, changePageSize);

    const prevPage = () => {
      if (setting.page == 1) {
        return false;
      }
      setting.page--;
    };

    const movePage = (page) => {
      setting.page = page;
    };

    const nextPage = () => {
      if (setting.page >= setting.maxPage) {
        return false;
      }
      setting.page++;
    };

    watch(
      () => props.rows,
      () => {
        if (props.isReSearch) {
          setting.page = 1;
        }
        nextTick(function () {
          let localElement = document.getElementsByClassName("is-rows-el");
          emit("is-finished", localElement);
        });
      }
    );

    if (props.hasCheckbox) {
      return {
        setting,
        rowCheckbox,
        checked,
        doSort,
        prevPage,
        movePage,
        nextPage,
      };
    } else {
      return {
        setting,
        doSort,
        prevPage,
        movePage,
        nextPage,
      };
    }
  },
});
</script>

<style scoped>
.checkbox-th {
  width: 1%;
}

.both {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC");
}

.sortable {
  cursor: pointer;
  background-position: right;
  background-repeat: no-repeat;
  padding-right: 30px !important;
}

.asc {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==);
}

.desc {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=);
}

.loading-mask {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.loading-content {
  display: table-cell;
  vertical-align: middle;
}

.loading-icon {
  display: block;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  /* -ms-flex-direction: column; */
  /* flex-direction: column; */
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--widgetBg);
  background-clip: border-box;
  /* height: 100%; */
  /* padding: 20px; */
}

.darkCard {
  background: var(--widgetBg);
  transition: var(--transition);
}

select {
  width: auto;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  height: auto;
  padding: 0px;
  margin-bottom: 0px;
}

table {
  table-layout: fixed;
  width: 100%;
  color: #212529;
  /* border-collapse: collapse; */
  border-collapse: separate;
  border-spacing: 0px 0;
}

th {
  text-align: inherit;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table td,
.table th {
  /* padding: 0.75rem; */
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table-border {
  border: 1px solid #dee2e6;
}

.table td,
.table th {
  vertical-align: middle;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table td,
.table th {
  /* padding: 0.75rem; */
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

/* td{
    display: inline-table;
  } */
.overflow-auto {
  background: #727174;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* margin-right: -15px;
    margin-left: -15px; */
}

.dataTables_wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}

div.dataTables_wrapper div.dataTables_paginate {
  white-space: nowrap;
  text-align: right;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: center;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}

@media (min-width: 576px) {
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

/* select {
      width: auto;
      border: none;
      background-color: unset;
      height: auto;
      padding: 0px;
      width: 100%;
      font-size: 8px;
      margin-bottom: 0px;
      text-align: center;
      display: flex;
      align-items: center;
      align-self: center;
  }
  option{
    background: black;
    text-align: center;
  }
  option:hover{
    background: #58b67f !important;
    color:white;
    padding:10px;
  }
  option:not(:checked) {
    background-color: #212529;
    color:white;
    padding:10px;
  }
  option:checked {
    background-color: #58b67f;
    color:white;
    padding:10px;
  }
  p + select {
    margin-bottom: 10px;
  }
  select {
    -webkit-appearance: none;
    outline: none;
    font-size: 8px;
    box-sizing: border-box;
    border-radius: 0;
    background: black;
    border: unset;
    padding: 0.5em;
  }

  select {
    color: white;
  }

  .placeholder {
    color: gray
  }

  .green {
    color: green;
  }

  .red {
    color: red;
    background:green
  }
  .red:hover {
    color: red;
    background:blue
  }
  .s-hidden {
      visibility:hidden;
      padding-right:10px;
  }
  .select {
      cursor:pointer;
      display:inline-block;
      position:relative;
      font:normal 11px/22px Arial, Sans-Serif;
      color:white;
      border:1px solid #ccc;
  }
  .styledSelect {
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      background-color:white;
      padding:0 10px;
      font-weight:bold;
  }
  .styledSelect:after {
      content:"";
      width:0;
      height:0;
      border:5px solid transparent;
      border-color:black transparent transparent transparent;
      position:absolute;
      top:9px;
      right:6px;
  }
  .styledSelect:active, .styledSelect.active {
      background-color:#eee;
  }
  .options {
      display:none;
      position:absolute;
      top:100%;
      right:0;
      left:0;
      z-index:999;
      margin:0 0;
      padding:0 0;
      list-style:none;
      border:1px solid #ccc;
      background-color:white;
      -webkit-box-shadow:0 1px 2px rgba(0, 0, 0, 0.2);
      -moz-box-shadow:0 1px 2px rgba(0, 0, 0, 0.2);
      box-shadow:0 1px 2px rgba(0, 0, 0, 0.2);
  }
  .options li {
      padding:0 6px;
      margin:0 0;
      padding:0 10px;
  }
  .options li:hover {
      background-color:#39f;
      color:white;
  } */

/* select {
      width: 100%;
      border: navajowhite;
      background-color: unset;
      height: auto;
      padding: 0 10px;
      margin-bottom: 0px;
      font-size: 8px;
      margin: 0 auto;
  }
  option{
    background: black;
  } */

select {
  width: 100%;
  box-shadow: 0 10px 25px rgb(0 0 0 / 50%);
  font-size: 8px;
  padding: 0 1.5em;
  background: #0e1016;
  color: white;
  /* appearance: none; */
  border: 0;
}

.custom-select-table {
  position: relative;
  font-size: 9.8px !important;
}

.custom-arrow {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  background: red;
  height: 100%;
  width: 4rem;
  pointer-events: none;
}

.custom-arrow::before,
.custom-arrow::after {
  --size: 1em;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.custom-arrow::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid red;
  top: 35%;
}

.custom-arrow::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid red;
  top: 65%;
}

.auto-font {
  font-size: 9.8px !important;
}
</style>
